import PropTypes from 'prop-types';

import { NewEmailIcon } from 'assets/icons';

import Button from 'components/Button';

import './styles.scss';

const ResetPasswordSent = ({ onResend, secondsToWait, email }) => (
  <div className="reset-password-sent-wrapper">
    <NewEmailIcon />

    <p className="text">
      We&apos;ve sent a password reset link to {email}.
      Please check your inbox (and spam/junk folder, just in case) and follow the instructions to reset your password.
    </p>

    <Button
      className="resend"
      variant="text"
      color="secondary"
      disabled={!!secondsToWait}
      onClick={onResend}
    >
      Resend Instructions
      {secondsToWait && ` (${secondsToWait})`}
    </Button>
  </div>
);

ResetPasswordSent.propTypes = {
  onResend: PropTypes.func.isRequired,
  secondsToWait: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
};

export default ResetPasswordSent;
